
import QuoteRequestFilter from 'tradingmate-modules/src/filters/QuoteRequestFilter'
import AdminQuoteRequest from 'tradingmate-modules/src/models/api/admin/quotes/AdminQuoteRequest'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import QuoteRequestStatus from 'tradingmate-modules/src/models/api/quotes/QuoteReqeuestStatus'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Component, Mixins } from 'vue-property-decorator'
import { SearchBar, SearchBarItem } from '@/components/search'
import Paging from '@/components/actions/Paging.vue'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { FormPage } from '@/mixins'
import { InputDateTime, InputText } from '@/components/inputs'

@Component({
  components: {
    Table,
    TableHeader,
    TableRow,
    TableData,
    Paging,
    SearchBar,
    SearchBarItem,
    InputText,
    InputDateTime
  }
})

export default class AdminQuoteRequestIndex extends Mixins(FormPage) {
  private quoteRequests: PagedResultSet<AdminQuoteRequest> | null = null
  private filter = new QuoteRequestFilter({ Statuses: [QuoteRequestStatus.None] })
  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.updateFilter()
    this.getQuoteRequests()
  }

  handleSearch (): void {
    this.updateQuery()
    this.getQuoteRequests()
  }

  updateQuery (): void {
    this.$router.push({
      query: {
        email: this.filter.Email?.length ? this.filter.Email : undefined,
        name: this.filter.FullName?.length ? this.filter.FullName : undefined,
        statuses: this.filter.Statuses?.toString().length ? this.filter.Statuses?.toString() : undefined
      }
    })
  }

  updateFilter (): void {
    this.filter.Email = this.$route.query.email?.toString()
    this.filter.FullName = this.$route.query.FullName?.toString()
    this.filter.Statuses = this.$route.query.Statuses
      ? this.$route.query.Statuses.toString().split(',').map((role) => {
        return parseInt(role)
      })
      : [QuoteRequestStatus.None]
  }

  getQuoteRequests (): void {
    this.loading = true
    Services.API.Admin.QuoteRequest.GetAdminQuoteRequests(this.filter)
      .then((returnData) => { this.quoteRequests = returnData })
      .finally(() => { this.loading = false })
  }

  handlePaged (page: number): void {
    this.filter.Page = page
    this.getQuoteRequests()
  }
}

